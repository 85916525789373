/*
 * @Author       : your name
 * @Date         : 2020-11-25 15:47:50
 * @LastEditTime : 2020-11-26 14:36:01
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/helper/request.js
 */
import axios from 'axios'
import VueRouter from 'vue-router'
import JSONBig from 'json-bigint'
import Vue from 'vue'

const serves = axios.create({
    baseURL: process.env.BASE_API,
    timeout: 10000
})

serves.defaults.transformResponse = [
    function (data) {
        try {
            return JSONBig.parse(data)
        } catch (err) {
            return data
        }
    }
]

// Set interceptors before sending requests;
serves.interceptors.request.use(
    config => {
        // console.log('config===>', config);
        // Set what data processing needs to be done before sending
        const token = localStorage.getItem('token')
        if (token && config.headers && !config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${token}`
        }

        if (config.method === 'get') {
            config.params = config.params || {}
        }

        if (config.method === 'post') {
            config.data = config.data || {}
        }

        return config
    },
    err => {
        console.log('serves.interceptors.request err --->', err)
        return Promise.reject(err)
    }
)

// Set request acceptance interceptor
serves.interceptors.response.use(
    res => {
        // console.log('res===>', res)

        if (res.data.statusCode === 'Failure' || res.data.statusCode === '01') {
            Vue.toasted.show(res.data.formExceptions[0].localizedMessage, {
                position: 'top-center',
                type: 'error',
                singleton: true,
                fullWidth: true,
                duration: 2000
            })
            return Promise.reject(res.data.formExceptions[0].localizedMessage)
        } else {
            if (res.data.error_code === 1001) {
                const router = new VueRouter()
                router.push('/account/login')
                return
            } else {
                if (res.data.data) {
                    return res.data.data
                    // Empty object return status code
                } else if (Object.keys(res.data).length !== 0) {
                    return res.data
                } else {
                    return res.data.error_code
                }
            }
        }
    },
    err => {
        // console.log('err===>', JSON.stringify(err))
        // Determine whether there is a timeout 'timeout' string in the request exception information
        if (err.message.includes('timeout')) {
            console.log('err:', err)
            console.error('network timeout')
            Vue.toasted.show('Network timeout', {
                position: 'top-center',
                type: 'error',
                singleton: true,
                fullWidth: true
            })
        }
        if (err.response && err.response.data && err.response.data.errors) {
            // console.log('err.response.data.errors----->', err.response.data.errors)
            const values = Object.values(err.response.data.errors)
            if (values && values[0] && values[0].length > 0) {
                // console.log('values[0][0]---->', values[0][0])
                // console.error(values[0][0])
            }
        }
        return Promise.reject(err)
    }
)

// Throw out the serves
export default serves
